import React from 'react'
import {
  Row,
  Col,
  Icon,
  Button,
  Menu,
  Dropdown,
  // Checkbox,
  message,
  Form,
  Input,
} from "antd"
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import commonActions from "Actions/common/commonActions.js";
import loginActions from "Actions/login/loginActions.js";
import WrapModal from 'Components/Modal/wrapModal'
import { formateDate } from 'Utils/utils'
import logo from 'Static/images/logo.png'
import './index.less'

const FormItem = Form.Item;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {},
      sysTime: null,
      modal: {
        title: null
      },
      isEyeOld: false,
      isEyeNew: false,
      isEyeNewConfirm: false,
      WrapModalLoading: false,
      visible: false,
    };
    this.toggleCollapsed = this.toggleCollapsed.bind(this)
    this.logoutConfirm = this.logoutConfirm.bind(this)
    this.onCheckbox = this.onCheckbox.bind(this)
    this.modifyPw = this.modifyPw.bind(this)
    this.personalCenter = this.personalCenter.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleOk = this.handleOk.bind(this)
  }

  componentDidMount() {
    let userInfo = localStorage.getItem('user_info')
    if (userInfo) {
      this.setState({
        userInfo: JSON.parse(userInfo)
      })
    }
    setInterval(() => {
      let sysTime = formateDate(new Date().getTime());
      this.setState({
        sysTime
      })
    }, 1000)
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
    };
  }

  toggleCollapsed() {
    // 菜单栏状态
    // const {commonActions, collapsed} = this.props;
    // // 每次咱开收缩都清空一下默认展开项
    // let openKeys = [];
    // commonActions.nvaLeftStatus(!collapsed, openKeys)
    const { commonActions, collapsed, openKeys, targetKey } = this.props;
    if (collapsed) {
      commonActions.nvaLeftStatus(!collapsed, this.targetOpenKeys ? this.targetOpenKeys : openKeys, targetKey);
      this.targetOpenKeys = [];
    } else {
      commonActions.nvaLeftStatus(!collapsed, this.targetOpenKeys ? this.targetOpenKeys : [], targetKey);
      this.targetOpenKeys = openKeys;
    }
  }

  personalCenter() {
    // 个人中心
    message.warning("待开放")
  }

  modifyPw() {
    // 修改密码
    const { modal } = this.state;
    modal.title = "修改密码";
    modal.width = 540;
    this.setState({
      modal,
      visible: true,
    })
  }

  logoutConfirm() {
    // 退出登录
    const { history, loginActions } = this.props;
    loginActions.loginOut()
      .then(res => {
        const { code } = res.payload;
        if (code === 200) {
          localStorage.clear();
          message.success("退出成功");
          history.push('/login');
        } else {
          message.error(res.payload.message)
        }
      }).catch(err => {
        message.error("网络错误，请稍后再试！")
      });

  }

  onCheckbox() {
    // topTab 显示隐藏
    const { commonActions, topTabShow } = this.props;
    commonActions.topTabStatus(!topTabShow);
  }

  handleOk() {
    const { form, commonActions } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      if (values.new_password !== values.new_password_confirm) {
        message.warning("两次输入密码不一致！");
        form.resetFields(["new_password", "new_password_confirm"]);
        return;
      }
      this.setState({
        WrapModalLoading: true
      });
      const data = {
        old_password: values.old_password,
        new_password: values.new_password,
      };
      commonActions.modifyMyPw(data)
        .then(res => {
          if (res.payload.code === 200) {
            message.success("修改成功");
            this.setState({ visible: false });
          } else {
            message.error(res.payload.message);
          }
          this.setState({
            WrapModalLoading: false,
          })
        }).catch(err => {
          message.error("网络错误，请稍后再试！");
          this.setState({ WrapModalLoading: false });
        })
    })
  }

  handleCancel() {
    this.setState({
      visible: false,
      WrapModalLoading: false,
    })
  }

  render() {
    // const { collapsed,topTabShow,form } = this.props;
    const { collapsed, form } = this.props;
    const {
      sysTime,
      modal,
      visible,
      userInfo,
      WrapModalLoading,
      isEyeOld,
      isEyeNew,
      isEyeNewConfirm,
    } = this.state;
    let subordinateDom = "";
    if (userInfo.hasOwnProperty("all_region")) {
      userInfo.all_region.forEach(val => {
        subordinateDom += val.name
      });
      if (userInfo.hasOwnProperty("org_name")) {
        subordinateDom += ` - ${userInfo.org_name}`
      }
    };

    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
    };
    let WrapModalContent = null;
    if (visible) {
      WrapModalContent = (
        <Form>
          <FormItem
            label="旧密码"
            {...formItemLayout}
          >
            {getFieldDecorator('old_password', {
              rules: [{ required: true, message: '新密码不能少于6位!', min: 6, }],
              validateTrigger: "onBlur",
            })(
              <Input
                allowClear
                type={isEyeOld ? null : "password"}
                placeholder="请输入旧密码"
                addonAfter={isEyeOld ?
                  <Icon onClick={() => this.setState({ isEyeOld: false })} style={{ fontSize: 18 }} type="eye-invisible" />
                  : <Icon onClick={() => this.setState({ isEyeOld: true })} style={{ fontSize: 18 }} theme="twoTone"
                    twoToneColor="#1890FF" type="eye" />
                }
              />
            )}
          </FormItem>
          <FormItem
            label="新密码"
            {...formItemLayout}
          >
            {getFieldDecorator('new_password', {
              rules: [{ required: true, message: '新密码不能少于6位!', min: 6, }],
              validateTrigger: "onBlur",
            })(
              <Input
                allowClear
                type={isEyeNew ? null : "password"}
                placeholder="请输入新密码"
                addonAfter={isEyeNew ?
                  <Icon onClick={() => this.setState({ isEyeNew: false })} style={{ fontSize: 18 }} type="eye-invisible" />
                  : <Icon onClick={() => this.setState({ isEyeNew: true })} style={{ fontSize: 18 }} theme="twoTone"
                    twoToneColor="#1890FF" type="eye" />
                }
              />
            )}
          </FormItem>
          <FormItem
            label="确认新密码"
            {...formItemLayout}
          >
            {getFieldDecorator('new_password_confirm', {
              rules: [{ required: true, message: '新密码不能少于6位!', min: 6, }],
              validateTrigger: "onBlur",
            })(
              <Input
                allowClear
                type={isEyeNewConfirm ? null : "password"}
                placeholder="请再次输入新密码"
                addonAfter={isEyeNewConfirm ?
                  <Icon onClick={() => this.setState({ isEyeNewConfirm: false })} style={{ fontSize: 18 }}
                    type="eye-invisible" />
                  : <Icon onClick={() => this.setState({ isEyeNewConfirm: true })} style={{ fontSize: 18 }} theme="twoTone"
                    twoToneColor="#1890FF" type="eye" />
                }
              />
            )}
          </FormItem>
        </Form>
      )
    }
    const dropdownMenu = (
      <Menu>
        <Menu.Item key="user" onClick={this.personalCenter}>
          <Icon type="user" />
          <span className="MenuItemIcon">个人中心</span>
        </Menu.Item>
        <Menu.Item key="modifyPw" onClick={this.modifyPw}>
          <i className={`trkj-iconfont tableActionIcon trkj-xiugaimima01`} />
          <span className="MenuItemI">修改密码</span>
        </Menu.Item>
        <Menu.Item key="logout" onClick={this.logoutConfirm}>
          <Icon type="logout" />
          <span className="MenuItemIcon">退出登录</span>
        </Menu.Item>
        {/*<Menu.Divider />*/}
        {/*隐藏快捷方式*/}
        {/*<Menu.Item key="topTabStatus" onClick={this.onCheckbox}>*/}
        {/*  <Checkbox checked={topTabShow}>*/}
        {/*    显示快捷导航栏*/}
        {/*  </Checkbox>*/}
        {/*</Menu.Item>*/}
      </Menu>
    );
    return (
      <div className="header">
        <Row className="header-top">
          <Col span={2} className="navStatus">
            <Button
              onClick={this.toggleCollapsed}
            >
              <Icon type={collapsed ? 'menu-unfold' : 'menu-fold'} />
            </Button>
          </Col>

          <Col span={12} className="logo">
            <img src={logo} alt="" />
            <span style={{ fontWeight: "bold", fontSize: 20 }}>{subordinateDom}</span>
          </Col>

          <Col className="weather" span={10}>
            <Dropdown
              overlay={dropdownMenu}
              overlayClassName="settingDropDown"
              trigger={['click']}
            >
              <Icon className="setting" type="setting" />
            </Dropdown>
            <span className="date">{sysTime ? sysTime : null}</span>
            欢迎，
            <span className="userName">{userInfo.user_name}</span>

          </Col>
        </Row>
        <WrapModal
          wrapClassName="applicationModal"
          modalConfig={modal}
          visible={visible}
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          loading={WrapModalLoading}
          content={WrapModalContent}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    targetKey: state.common.targetKey,
    openKeys: state.common.openKeys,
    collapsed: state.common.collapsed,
    topTabShow: state.common.topTabShow,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
    loginActions: bindActionCreators(loginActions, dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(withRouter(Header)));
